import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import { getApiObject, getFieldObject } from '@/utils/buildApi'

const getUpdatedFields = ({ state, item, fieldId }) => {
  const relatedFieldId = fieldId || state.selectedFieldId

  return state.api.fields.map(field =>
    field.id === relatedFieldId
      ? {
          ...field,
          ...item,
        }
      : field,
  )
}
const getApiHistoryInitialState = () => ({
  _pastApis: [], // It is for undo history
  _futureApis: [], // It is for redo history
  isActiveHistorySetMode: false, // To adjust normal set or history set
})

const buildApiSlice = createSlice({
  name: 'buildApi',
  initialState: {
    api: {},
    apiHistory: getApiHistoryInitialState(),
    isActiveRightPanel: false,
    selectedFieldId: null,
    projectApiGroups: [],
    isDirty: false,
    isEnableSettingsPopup: false,
    isEnabledPreview: false,
  },
  reducers: {
    setIsActiveRightPanel: (state, action) => {
      state.isActiveRightPanel = action.payload
    },
    setInitialApi: state => {
      state.api = getApiObject()
    },
    setApi: (state, action) => {
      state.api = {
        ...action.payload,
      }
    },
    addPastApi: (state, action) => {
      state.apiHistory._pastApis = [...state.apiHistory._pastApis, action.payload]
    },
    addFutureApi: (state, action) => {
      state.apiHistory._futureApis = [action.payload, ...state.apiHistory._futureApis]
    },
    clearFutureApis: state => {
      state.apiHistory._futureApis = []
    },
    undoApi: state => {
      state.apiHistory.isActiveHistorySetMode = true

      state.apiHistory._futureApis = [cloneDeep(state.api), ...state.apiHistory._futureApis]
      state.api = cloneDeep(state.apiHistory._pastApis[state.apiHistory._pastApis.length - 1])

      state.apiHistory._pastApis = state.apiHistory._pastApis.slice(0, -1)
    },
    redoApi: state => {
      state.apiHistory.isActiveHistorySetMode = true

      state.apiHistory._pastApis = cloneDeep([...state.apiHistory._pastApis, state.api])
      state.api = cloneDeep(state.apiHistory._futureApis[0])

      state.apiHistory._futureApis = state.apiHistory._futureApis.slice(1)
    },
    setIsActiveHistorySetMode: (state, action) => {
      state.apiHistory.isActiveHistorySetMode = action.payload
    },
    setApiTitle: (state, action) => {
      state.api.title = action.payload
    },
    setApiFields: (state, action) => {
      state.api.fields = action.payload.map(({ properties = {}, ...otherPayload }, index) => ({
        ...otherPayload,
        properties: {
          ...properties,
          orderIndex: index,
        },
      }))
    },
    setSelectedFieldId: (state, action) => {
      state.selectedFieldId = action.payload || null
    },
    selectField: (state, action) => {
      state.selectedFieldId = action.payload
      state.isActiveRightPanel = true
    },
    setProjectApiGroups: (state, action) => {
      state.projectApiGroups = action.payload
    },
    addProjectApiGroups: (state, action) => {
      state.projectApiGroups = [...state.projectApiGroups, action.payload]
    },
    setApiGroupId: (state, action) => {
      state.api.apiGroupId = action.payload
    },
    setApiMethod: (state, action) => {
      state.api.method = action.payload
    },
    setApiUrl: (state, action) => {
      state.api.url = action.payload
    },
    setApiDescription: (state, action) => {
      state.api.description = action.payload
    },
    setApiHeader: (state, action) => {
      state.api.header = { ...action.payload }
    },
    setApiOtherFields: (state, action) => {
      state.api.otherFields = { ...action.payload }
    },
    setApiDefaultBody: (state, action) => {
      state.api.defaultBody = { ...action.payload }
    },
    setApiDefaultUrlQueryParams: (state, action) => {
      state.api.defaultUrlQueryParams = { ...action.payload }
    },
    setApiConfigs: (state, action) => {
      state.api.configs = { ...action.payload }
    },
    updateApiConfigs: (state, action) => {
      state.api.configs = { ...state.api.configs, ...action.payload }
    },
    setApiFieldKey: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { key: action.payload } })
    },
    setApiFieldLabel: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { label: action.payload } })
    },
    setApiFieldDefaultValue: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { defaultValue: action.payload } })
    },
    setApiFieldScopeType: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { fieldScopeType: action.payload } })
    },
    setApiFieldProperties: (state, action) => {
      state.api.fields = state.api.fields.map(field =>
        field.id === state.selectedFieldId
          ? {
              ...field,
              properties: {
                ...field?.properties,
                ...action.payload,
              },
            }
          : field,
      )
    },
    setApiFieldType: (state, action) => {
      const { selectedField, newType } = action.payload

      // eslint-disable-next-line no-unused-vars
      const { properties, type, defaultValue, ...otherFieldPayload } = selectedField

      const newChangedField = getFieldObject({
        type: newType,
        customFieldObject: otherFieldPayload,
      })
      state.api.fields = getUpdatedFields({ state, item: newChangedField, fieldId: selectedField.id })
    },
    setIsDirty: (state, action) => {
      state.isDirty = action.payload
    },
    setInitialState: state => {
      state.isActiveRightPanel = true
      state.selectedFieldId = null
      state.isDirty = false
      state.isEnableSettingsPopup = false
      state.isEnabledPreview = false
      state.apiHistory = getApiHistoryInitialState()
    },
    setIsEnableSettingsPopup: (state, action) => {
      state.isEnableSettingsPopup = action.payload
    },
    setIsEnabledPreview: (state, action) => {
      state.isEnabledPreview = action.payload
    },
  },
})

const selectedFieldSelector = state => {
  const buildApi = state.buildApi

  return buildApi.api.fields.find(field => field.id === buildApi.selectedFieldId)
}

export const buildApiReducerActions = buildApiSlice.actions
export const buildApiReducerSelectors = {
  selectedFieldSelector,
}

export default buildApiSlice.reducer
