import { useDispatch } from 'react-redux'

import { appReducerActions } from '@/store/reducers/app'
import { setActiveProjectId } from '@/utils/localStorageActions'
import { setSessionActiveProjectId } from '@/utils/sessionStorageActions'

const useActiveProjectIdActions = () => {
  const dispatch = useDispatch()

  const handleSetActiveProjectId = projectId => {
    setActiveProjectId(projectId)
    setSessionActiveProjectId(projectId)

    dispatch(appReducerActions.setActiveProjectId(projectId))
  }

  return { handleSetActiveProjectId }
}

export default useActiveProjectIdActions
