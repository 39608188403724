export default {
  'test.lang': 'test türkçe',
  'postmanBuilder.back': 'geri',
  'postmanBuilder.dataStep.error.process': 'Koleksiyon işlenirken bir hata meydana geldi.',
  'postmanBuilder.dataStep.title': 'Postman Builder',
  'postmanBuilder.dataStep.selectCollection': 'Bir koleksiyon seç',
  'postmanBuilder.manageActions.title': 'Aksiyon Yönetme',
  'postmanBuilder.completeProcess.error.general': 'Bir hata meydana geldi',
  'postmanBuilder.completeProcess.success.title': 'Başarılı!',
  'postmanBuilder.completeProcess.success.redirecting': 'Yönlendiriliyorsunuz...',
  'postmanBuilder.completeProcess.info': 'Artık her şey hazır! Bu adımdan sonra aksiyonların otomatik olarak oluşturulmuş olacak.',
  'postmanBuilder.completeProcess.button.create': 'Şimdi Oluştur',
  'postmanBuilder.completeProcess.title': 'Her şey hazır.',
  'postmanBuilder.completeProcess.button.continue': 'Oluştur',
  'postmanBuilder.adjustDefaultVariables.defaultHeader': 'Default Header',
  'postmanBuilder.adjustDefaultVariables.defaultBody': 'Default Body',
  'postmanBuilder.adjustDefaultVariables.defaultUrlQueryParams': 'Default Url Query Params',
  'postmanBuilder.adjustDefaultVariables.title': 'Varsayılan Değerleri Yönetme',
  'postmanBuilder.askQuestions.title': 'Son bir adım daha...',
  'postmanBuilder.askQuestions.baseUrl.title': 'Temel URL Bilgileri',
  'postmanBuilder.askQuestions.baseUrl.placeholder': 'Örn: https://exp.com/api',
  'postmanBuilder.askQuestions.baseUrl.text': 'adında temel URL sahibiyim',
  'postmanBuilder.askQuestions.baseUrlKey.placeholder': 'Örn: base_url',
  'postmanBuilder.askQuestions.baseUrlKey.text': 'key isminde Postman içinde temel URL olarak kullanıyorum.',
  'postmanBuilder.step.brand.console': 'console',
  'postmanBuilder.step.brand.postman': 'postman',
  'postmanBuilder.step.button.back': 'Geri',
  'postmanBuilder.step.button.continue': 'Devam',
  'postmanBuilder.step.info.existingActions':
    'Zaten aksiyonlarınız var. Postman entegrasyonu ile varolan aksiyonlar haricinde yeni aksiyonlar eklenecektir.',
  'actionMenuList.noActions': 'Hiç eklenmiş aksiyon yok',
  'actionMenuList.searchPlaceholder': 'Aksiyon ara...',
  'actionMenuList.noFilteredActions': 'Hiç aksiyon yok',
  'apiInformation.url.label': 'Url*',
  'apiInformation.url.dynamicField': "Dinamik alan için ':' ile başlayın",
  'apiInformation.url.tooltip': 'Alan tipi url olup bağımlılık olarak eklenmiş ve varsayılan olarak eklenmiş parametreler kullanılabilir',
  'apiInformation.method.label': 'Method*',
  'apiInformation.method.placeholder': 'Method seç...',
  'apiInformation.test.button': 'Test Et',
  'apiInformationResult.error': 'Bir hata meydana geldi',
  'apiInformationResultPopup.title': 'Sonuç',
  'authenticationFlow.highlight.simple': 'Basit.',
  'authenticationFlow.highlight.easy': 'Kolay.',
  'authenticationFlow.highlight.functional': 'İşlevsel.',
  'colorPicker.placeholder': 'Renk seç',
  'continueAskPopup.button.no': 'Hayır',
  'continueAskPopup.button.yes': 'Evet',
  'dynamicChart.filter.button': 'Filtre',
  'dynamicChart.filter.apply': 'Uygula',
  'editTable.toast.deleteSuccess': 'Başarıyla silindi!',
  'editTable.toast.error': 'Bir hata oluştu',
  'editTable.toast.updateSuccess': 'Başarıyla güncellendi!',
  'editTable.delete.title': 'Silmek üzeresiniz',
  'editTable.delete.description': 'Silmek istediğinize emin misiniz?',
  'errorPage.message': 'Bir hata oluştu, bununla ilgileniyoruz.',
  'errorPage.button.retry': 'Tekrar Yükle',
  'fileInput.button.upload': 'Yükle',
  'fileInput.noFiles': 'Henüz hiçbir şey yüklenmemiş',
  'fileInput.preview.title': 'Önizleme',
  'fileInput.preview.unknownFile': 'Bilinmeyen dosya',
  'fileUpload.error.type': 'Geçerli dosya tipi değil',
  'fileUpload.error.size': 'Geçerli boyutta değil',
  'fileUpload.dropHere': 'Buraya bırak',
  'fileUpload.dragOrUpload': 'Sürükle bırak ya da yükle',
  'richTextEditor.clickToEdit': 'Düzenlemek için tıklayın',
  'richTextEditor.popup.title': 'Düzenle',
  'richTextEditor.popup.save': 'Kaydet',
  'input.noData': 'Hiç veri yok',
  'input.search': 'Ara..',
  'jsonEditor.error.invalid': 'Geçersiz JSON',
  'manageActions.noValue': 'Hiç değer yok',
  'manageActions.selectedCount': '{count} adet seçilmiş aksiyon',
  'multipleInputList.noItem': 'Hiç bir şey eklenmemiş',
  'multipleInputList.addButton': 'Ekle',
  'offlinePage.message': 'Çevrimdışı gözüküyorsunuz, internet bağlantınızı kontrol ediniz.',
  'permissionError.title': 'İzinsiz Alan',
  'permissionError.description': 'Bu alana erişmek için izniniz yok. Hata olduğunu düşünüyosanız yöneticiniz ile iletişime geçiniz.',
  'permissionError.backHome': 'Ana Sayfa Git',
  'table.action': 'İşlem',
  'table.totalResult': 'Toplam {count} veri',
  'table.noData': 'Hiç veri yok',
  'table.addButton': 'Ekle',
  'widgetFallBackError.message': 'Bir hata meydana geldi',
  'buildApi.error.general': 'Bir hata oluştu',
  'buildApi.formPage.dragHere': 'Sol panelden buraya sürükle',
  'buildApi.leftPanel.title': 'Form Elemanları',
  'buildApi.leftPanel.menu.basic': 'TEMEL',
  'buildApi.leftPanel.menu.advanced': 'GELİŞMİŞ',
  'buildApi.rightPanel.settings': 'Ayarlar',
  'buildApi.rightPanel.fieldSettings.menu.general': 'GENEL',
  'buildApi.rightPanel.fieldSettings.menu.advanced': 'GELİŞMİŞ',
  'buildApi.rightPanel.fieldSettings.defaultValue.placeholder': 'Varsayılan değer seç...',
  'buildApi.rightPanel.fieldSettings.noChoices': 'Hiç eklenmiş seçenek yok',
  'buildApi.rightPanel.fieldSettings.selectPhoto': 'Fotoğraf Seç',
  'buildApi.rightPanel.fieldSettings.toggleSwitch.on': 'Açık',
  'buildApi.rightPanel.fieldSettings.toggleSwitch.off': 'Kapalı',
  'buildApi.rightPanel.fieldSettings.date.placeholder': 'Bir tarih seç...',
  'buildApi.rightPanel.fieldSettings.time.placeholder': 'Bir saat seç...',
  'buildApi.rightPanel.fieldSettings.defaultValue.input.placeholder': 'Varsayılan Değer',
  'buildApi.rightPanel.fieldSettings.key.title': 'Key*',
  'buildApi.rightPanel.fieldSettings.key.tooltip': 'Benzersiz değer',
  'buildApi.rightPanel.fieldSettings.key.placeholder': 'Key',
  'buildApi.rightPanel.fieldSettings.title.label': 'Başlık',
  'buildApi.rightPanel.fieldSettings.title.placeholder': 'Başlık',
  'buildApi.rightPanel.fieldSettings.description': 'Açıklama',
  'buildApi.rightPanel.fieldSettings.type.title': 'Tip*',
  'buildApi.rightPanel.fieldSettings.type.placeholder': 'Tip seç...',
  'buildApi.rightPanel.fieldSettings.defaultValue.title': 'Varsayılan Değer',
  'buildApi.rightPanel.fieldSettings.rules': 'Kurallar',
  'buildApi.rightPanel.fieldSettings.hide': 'Gizle',
  'buildApi.rightPanel.fieldSettings.exclude.title': 'Dahil Etme',
  'buildApi.rightPanel.fieldSettings.exclude.tooltip': 'API içinde gitmeme durumu',
  'buildApi.rightPanel.fieldSettings.dependency': 'Bağımlılık Durumu',
  'buildApi.rightPanel.fieldSettings.description.type.external': 'Harici',
  'buildApi.rightPanel.fieldSettings.description.type.hint': 'İpucu',
  'buildApi.rightPanel.fieldSettings.description.placeholder': 'Bir şey yaz...',
  'buildApi.rightPanel.fieldSettings.rules.allRules': 'Tüm Kurallar',
  'buildApi.rightPanel.fieldSettings.rules.popup.title': 'Tüm Kurallar',
  'buildApi.rightPanel.fieldSettings.rules.noRules': 'Hiç kural eklenmemiş',
  'buildApi.rightPanel.fieldSettings.rules.addRule': 'Kural Ekle',
  'buildApi.rightPanel.fieldSettings.rules.back': 'Geri',
  'buildApi.rightPanel.fieldSettings.rules.selectRule': 'Bir kural seç',
  'buildApi.rightPanel.fieldSettings.rules.added': 'Eklenmiş',
  'buildApi.rightPanel.fieldSettings.rules.save': 'Kaydet',
  'buildApi.rightPanel.fieldSettings.rules.value': 'Değer',
  'buildApi.top.toast.updateSuccess': 'Aksiyon başarıyla güncellendi',
  'buildApi.top.toast.createSuccess': 'Aksiyon başarıyla oluşturuldu',
  'buildApi.top.toast.error': 'Bir hata oluştu',
  'buildApi.top.confirm.unsavedChanges': 'Henüz değişiklikler kaydedilmedi, devam etmeye emin misin?',
  'buildApi.top.back': 'geri',
  'buildApi.top.title.placeholder': 'Başlık gir',
  'buildApi.top.goWithoutSaving': 'kaydetmeden git',
  'buildApi.top.save': 'Kaydet',
  'buildApi.settings.toast.deleteSuccess': 'Aksiyon başarıyla silindi',
  'buildApi.settings.toast.error': 'Bir hata meydana geldi',
  'buildApi.settings.popup.title': 'Ayarlar',
  'buildApi.settings.delete.title': 'Silmek istediğine emin misin?',
  'buildApi.settings.delete.description': 'Bu aksiyon silindikten sonra geri alınamaz.',
  'buildApi.settings.delete.button': 'Aksiyonu Sil',
  'buildApi.editTable.advanced.title': 'Başlık',
  'buildApi.editTable.advanced.dataKey.title': 'Data Key',
  'buildApi.editTable.advanced.dataKey.tooltip':
    'Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")',
  'buildApi.editTable.advanced.dynamicPage.title': 'API Dinamik Sayfalama Durumu',
  'buildApi.editTable.advanced.dynamicPage.tooltip':
    'Eğer verilerin parça parça geliyor ise pagination için Limit Key ve Total Key belirlemelisin.',
  'buildApi.editTable.advanced.columns': 'Tablo Kolonları',
  'buildApi.editTable.advanced.actions': 'İşlemler',
  'buildApi.editTable.advanced.filters': 'Filtreler',
  'buildApi.editTable.advanced.other': 'Diğer',
  'buildApi.editTable.advanced.styles': 'Stiller',
  'buildApi.apiSettings.menu.general': 'GENEL',
  'buildApi.apiSettings.menu.api': 'API',
  'buildApi.apiSettings.menu.advanced': 'GELİŞMİŞ',
  'buildApi.apiSettings.bodyType.formData': 'Form Data',
  'buildApi.apiSettings.bodyType.formUrlEncoded': 'Form URL Encoded',
  'buildApi.apiSettings.bodyType.none': 'Hiçbiri',
  'buildApi.apiSettings.general.group': 'Grup',
  'buildApi.apiSettings.general.groupPlaceholder': 'Grup seç...',
  'buildApi.apiSettings.general.description': 'Açıklama',
  'buildApi.apiSettings.general.descriptionPlaceholder': 'Açıklama gir...',
  'buildApi.apiSettings.general.confirmationWindow': 'Onay Penceresi',
  'buildApi.apiSettings.api.completeButtonStatus': 'Tamamla Butonu Durumu',
  'buildApi.apiSettings.api.completeButtonDisabledInfo': 'Tamamla butonu devre dışı bırakıldı',
  'buildApi.apiSettings.api.completeButtonEnabledInfo': 'Tamamla butonu aktif',
  'buildApi.apiSettings.api.disableCompleteButton': 'Tamamla Butonunu Devre Dışı Bırak',
  'buildApi.apiSettings.api.urlDynamicFieldPrefix': "Dinamik alan için ':' ile başlayın",
  'buildApi.apiSettings.api.urlDynamicFieldTooltip':
    'Alan tipi url olup bağımlılık olarak eklenmiş ve varsayılan olarak eklenmiş parametreler kullanılabilir',
  'buildApi.apiSettings.api.method': 'Method',
  'buildApi.apiSettings.apiGroup.errors.updateFailed': 'Grup güncellenirken bir hata oluştu',
  'buildApi.apiSettings.apiGroup.errors.saveFailed': 'Grup kaydedilirken bir hata oluştu',
  'buildApi.apiSettings.apiGroup.errors.deleteFailed': 'Grup silinirken bir hata oluştu',
  'buildApi.apiSettings.apiGroup.actionCount': '{count} adet aksiyon',
  'buildApi.apiSettings.apiGroup.title': 'API Grupları',
  'buildApi.apiSettings.apiGroup.groupNamePlaceholder': 'Grup adı gir...',
  'buildApi.apiSettings.apiGroup.save': 'Kaydet',
  'buildApi.apiSettings.confirmation.defaultTitle': 'Bu işlemi yapmak istediğinize emin misiniz?',
  'buildApi.apiSettings.confirmation.defaultDescription': 'Bu işlem geri alınamaz.',
  'buildApi.apiSettings.confirmation.title': 'Başlık',
  'buildApi.apiSettings.confirmation.titlePlaceholder': 'Başlık gir...',
  'buildApi.apiSettings.confirmation.description': 'Açıklama',
  'buildApi.apiSettings.confirmation.descriptionPlaceholder': 'Açıklama gir...',
  'buildApi.apiSettings.api.header': 'Header',
  'buildApi.apiSettings.api.defaultBody': 'Default Body',
  'buildApi.apiSettings.api.defaultUrlQueryParams': 'Default URL Query Params',
  'buildApi.apiSettings.api.otherFields': 'Diğer Alanlar',
  'buildApi.apiSettings.api.bodyType': 'Body Tipi',
  'buildApi.apiSettings.api.methodPlaceholder': 'Method seç...',
  'buildApi.apiSettings.api.headers': 'Default Headers',
  'buildApi.apiSettings.advanced.otherFields': 'Diğer',
  'buildApi.field.type.string': 'Kısa Metin',
  'buildApi.field.type.textarea': 'Uzun Metin',
  'buildApi.field.type.number': 'Sayı',
  'buildApi.field.type.password': 'Şifre',
  'buildApi.field.type.select': 'Açılır Liste',
  'buildApi.field.type.remoteSelect': 'Dinamik Açılır Liste',
  'buildApi.field.type.image': 'Fotoğraf',
  'buildApi.field.type.richText': 'Düzenlenebilir Metin',
  'buildApi.field.type.toggleSwitch': 'Açılır/Kapanır Buton',
  'buildApi.field.type.date': 'Tarih Seçici',
  'buildApi.field.type.hour': 'Saat Seçici',
  'buildApi.field.type.file': 'Dosya Yükleme',
  'buildApi.field.type.colorPicker': 'Renk Seçici',
  'buildApi.field.type.beautifyJson': 'JSON Düzenleyici',
  'buildApi.field.type.editTable': 'Tablo Düzenleyici',
  'buildApi.field.type.unknown': 'Bilinmeyen Eleman',
  'buildApi.field.rule.required': 'Zorunlu Alan',
  'buildApi.field.rule.min': 'Minimum Değer',
  'buildApi.field.rule.max': 'Maksimum Değer',
  'buildApi.field.rule.unknown': 'Bilinmeyen Kural',
  'buildApi.field.error': 'Bir hata meydana geldi',
  'buildApi.field.label.placeholder': 'Bir şey yaz...',
  'buildApi.field.type.unknownField': 'Bilinmeyen alan tipi',
  'buildApi.field.notIncluded': 'Dahil edilmedi',
  'buildApi.field.dependent': 'Bağımlı',
  'buildApi.field.previewEditTable': 'Tablo Düzenleyici',
  'buildApi.submitButton': 'Tamamla',
  'buildApi.toggleSwitch.advanced.activeValue.title': 'Aktif Değer',
  'buildApi.toggleSwitch.advanced.activeValue.placeholder': 'Aktif değer gir...',
  'buildApi.toggleSwitch.advanced.boolean.information': 'Şu an boolean <span>{value}</span> değer.',
  'buildApi.toggleSwitch.advanced.boolean.change': 'Değiştir',
  'buildApi.toggleSwitch.advanced.boolean.turnDefaultValue': 'Varsayılana dön',
  'buildApi.toggleSwitch.advanced.boolean.activeValue': 'Aktif',
  'buildApi.toggleSwitch.advanced.boolean.passiveValue': 'Pasif',
  'buildApi.toggleSwitch.advanced.passiveValue.title': 'Pasif Değer',
  'buildApi.toggleSwitch.advanced.passiveValue.placeholder': 'Pasif değer gir...',
  'buildApi.select.advanced.options.title': 'Seçenekler',
  'buildApi.select.advanced.options.label': 'Etiket',
  'buildApi.select.advanced.options.value': 'Değer',
  'buildApi.remoteSelect.advanced.dataKey.title': 'Data Key',
  'buildApi.remoteSelect.advanced.dataKey.placeholder': 'Data key gir...',
  'buildApi.remoteSelect.advanced.labelKey.title': 'Label Key',
  'buildApi.remoteSelect.advanced.labelKey.placeholder': 'Label key gir...',
  'buildApi.remoteSelect.advanced.valueKey.title': 'Value Key',
  'buildApi.remoteSelect.advanced.valueKey.placeholder': 'Value key gir...',
  'buildApi.remoteSelect.advanced.searchParam.title': 'Arama Parametresi',
  'buildApi.remoteSelect.advanced.searchParam.placeholder': 'Arama parametresi gir...',
  'buildApi.remoteSelect.advanced.dependencies.title': 'Bağımlılıklar',
  'buildApi.remoteSelect.advanced.dependencies.key': 'Key',
  'buildApi.remoteSelect.advanced.dependencies.type': 'Tip',
  'buildApi.remoteSelect.advanced.header.title': 'Header',
  'buildApi.file.advanced.multipleUpload.title': 'Çoklu Yükleme',
  'buildApi.file.advanced.supportedTypes.title': 'Desteklenen Dosya Tipleri',
  'buildApi.file.advanced.onlyAccept.photo': 'Fotoğraf',
  'buildApi.file.advanced.onlyAccept.video': 'Video',
  'buildApi.file.advanced.onlyAccept.audio': 'Ses',
  'buildApi.file.advanced.onlyAccept.all': 'Tümü',
  'buildApi.editTable.advanced.sections.title': 'Başlık',
  'buildApi.editTable.advanced.sections.dataKeyTooltip':
    'Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")',
  'buildApi.editTable.advanced.sections.dynamicPaging': 'API Dinamik Sayfalama Durumu',
  'buildApi.editTable.advanced.sections.dynamicPagingTooltip':
    'Eğer verilerin parça parça geliyor ise pagination için Limit Key ve Total Key belirlemelisin.',
  'buildApi.editTable.advanced.sections.tableColumns': 'Tablo Kolonları',
  'buildApi.editTable.advanced.sections.actions': 'İşlemler',
  'buildApi.editTable.advanced.sections.filters': 'Filtreler',
  'buildApi.editTable.advanced.sections.other': 'Diğer',
  'buildApi.editTable.advanced.sections.styles': 'Stiller',
  'buildApi.editTable.advanced.styles.height': 'Yükseklik',
  'buildApi.editTable.advanced.dynamicPage.limitKey': 'Limit Key',
  'buildApi.editTable.advanced.dynamicPage.limitKeyTooltip': 'Sayfalama için kullanılacak limit parametresinin key değeri',
  'buildApi.editTable.advanced.dynamicPage.totalKey': 'Total Key',
  'buildApi.editTable.advanced.dynamicPage.totalKeyTooltip': 'Toplam veri sayısının bulunduğu key değeri',
  'buildApi.editTable.advanced.other.emptyDataWrapper': 'Veri yoksa boş alan göster',
  'buildApi.editTable.advanced.filters.popup.allFilters': 'Tüm Filtreler',
  'buildApi.editTable.advanced.filters.popup.newFilter': 'Yeni Filtre',
  'buildApi.editTable.advanced.filters.popup.editFilter': 'Filtreyi Düzenle',
  'buildApi.editTable.advanced.columns.viewAll': 'Tüm Kolonları Görüntüle',
  'buildApi.editTable.advanced.columns.popup.allColumns': 'Tüm Kolonlar',
  'buildApi.editTable.advanced.columns.popup.newColumn': 'Yeni Kolon',
  'buildApi.editTable.advanced.columns.popup.editColumn': 'Kolonu Düzenle',
  'buildApi.editTable.advanced.columns.popup.addNewColumn': 'Yeni Kolon Ekle',
  'buildApi.editTable.advanced.columns.popup.noColumns': 'Henüz kolon eklenmemiş',
  'buildApi.editTable.advanced.columns.edit.back': 'Geri',
  'buildApi.editTable.advanced.columns.edit.title': 'Başlık',
  'buildApi.editTable.advanced.columns.edit.dataIndex': 'Data Index',
  'buildApi.editTable.advanced.columns.edit.other': 'Diğer',
  'buildApi.editTable.advanced.columns.edit.isEditable': 'Düzenlenebilir(Şu an yalnızca metin alanı için)',
  'buildApi.editTable.advanced.columns.edit.isUsableOnUrl': "URL'de Kullanılabilir",
  'buildApi.editTable.advanced.columns.edit.save': 'Kaydet',
  'buildApi.editTable.advanced.columns.list.columnTitle': 'Kolon Başlığı',
  'buildApi.editTable.advanced.columns.list.columnName': 'Kolon Adı',
  'buildApi.editTable.advanced.columns.list.actions': 'İşlemler',
  'buildApi.editTable.advanced.actions.viewAll': 'Tüm İşlemleri Görüntüle',
  'buildApi.editTable.advanced.actions.popup.allActions': 'Tüm İşlemler',
  'buildApi.editTable.advanced.actions.popup.editAdd': 'Ekleme İşlemini Düzenle',
  'buildApi.editTable.advanced.actions.popup.editUpdate': 'Güncelleme İşlemini Düzenle',
  'buildApi.editTable.advanced.actions.popup.editDelete': 'Silme İşlemini Düzenle',
  'buildApi.editTable.advanced.actions.edit.back': 'Geri',
  'buildApi.editTable.advanced.actions.edit.urlDynamicFieldPrefix': "Dinamik alan için ':' ile başlayın",
  'buildApi.editTable.advanced.actions.edit.urlDynamicFieldTooltip':
    'Alan tipi url olup bağımlılık olarak eklenmiş ve varsayılan olarak eklenmiş parametreler kullanılabilir',
  'buildApi.editTable.advanced.actions.edit.method': 'Method',
  'buildApi.editTable.advanced.actions.edit.methodPlaceholder': 'Method seç...',
  'buildApi.editTable.advanced.actions.edit.bodyType': 'Body Tipi',
  'buildApi.editTable.advanced.actions.edit.bodyType.formData': 'Form Data',
  'buildApi.editTable.advanced.actions.edit.bodyType.formUrlEncoded': 'Form URL Encoded',
  'buildApi.editTable.advanced.actions.edit.bodyType.none': 'Hiçbiri',
  'buildApi.editTable.advanced.actions.edit.defaultBody': 'Default Body',
  'buildApi.editTable.advanced.actions.edit.defaultBodyKeys': 'Default Body Key',
  'buildApi.editTable.advanced.actions.edit.delete': 'Sil',
  'buildApi.editTable.advanced.actions.edit.save': 'Kaydet',
  'buildApi.editTable.advanced.actions.list.addAction': 'Ekleme İşlemi',
  'buildApi.editTable.advanced.actions.list.addActionDetails': 'Ekleme işlemi detaylarını düzenle',
  'buildApi.editTable.advanced.actions.list.updateAction': 'Güncelleme İşlemi',
  'buildApi.editTable.advanced.actions.list.updateActionDetails': 'Güncelleme işlemi detaylarını düzenle',
  'buildApi.editTable.advanced.actions.list.deleteAction': 'Silme İşlemi',
  'buildApi.editTable.advanced.actions.list.deleteActionDetails': 'Silme işlemi detaylarını düzenle',
  'buildApi.editTable.advanced.actions.list.edited': 'Düzenlendi',
  'buildApi.editTable.advanced.filters.viewAll': 'Tüm Filtreleri Görüntüle',
  'changePassword.input.newPassword.placeholder': 'Yeni şifre',
  'changePassword.input.repeatNewPassword.placeholder': 'Yeni şifre tekrar',
  'changePassword.button.changePassword': 'Şifre Değiştir',
  'changePassword.toast.error.passwordMatch': 'Girilen şifreler aynı olmalıdır',
  'changePassword.toast.success': 'Şifre başarıyla değiştirildi',
  'changePassword.toast.error': 'Bir hata oluştu',
  'forgetPassword.title': 'Şifremi Unuttum',
  'forgetPassword.input.email.placeholder': 'Email',
  'forgetPassword.button.send': 'Gönder',
  'forgetPassword.link.login': 'Hesabın mı var? Giriş Yap',
  'forgetPassword.success.title': 'Başarılı',
  'forgetPassword.success.description': '{email} adresine email gönderdik.',
  'forgetPassword.success.login': 'Şimdi giriş yap',
  'forgetPassword.toast.error.emailNotFound': 'Böyle bir email sistemde kayıtlı değil',
  'forgetPassword.toast.error': 'Bir hata oluştu',
  'login.title': 'Şimdi Giriş Yap!',
  'login.input.email.placeholder': 'E-mail',
  'login.input.password.placeholder': 'Şifre',
  'login.link.forgetPassword': 'Şifremi unuttum',
  'login.button.login': 'Giriş Yap',
  'login.text.or': 'veya',
  'login.button.inavoAccount': 'inavo Account İle Giriş Yap',
  'login.link.register': 'Hesabın mı yok? Kayıt ol',
  'login.toast.error.invalidCredentials': 'E-mail ya da şifre yanlış',
  'login.toast.error': 'Bir hata oluştu, bu konun üzerinde çalışıyoruz.',
  'register.title': 'Kayıt Ol!',
  'register.input.name.placeholder': 'İsim*',
  'register.input.email.placeholder': 'Email*',
  'register.input.password.placeholder': 'Şifre*',
  'register.button.register': 'Kayıt Ol',
  'register.link.login': 'Hesabın mı var? Giriş Yap',
  'register.toast.success': 'Başarıyla kayıt olundu',
  'register.toast.error.userExists': 'Bu kullanıcı zaten kayıt olmuş',
  'register.toast.error': 'Bir hata oluştu',
  'closeButton.error': 'Bir hata oluştu',
  'projectSwitcher.searchPlaceholder': 'Proje ara...',
  'projectSwitcher.noProjects': 'Hiç proje yok',
  'projectSwitcher.createProject': 'Proje Oluştur',
  'userUpdatePassword.success.update': 'Şifre başarıyla değiştirildi',
  'userUpdatePassword.success.logout': 'Diğer hesaplardan çıkış yapıldı',
  'userUpdatePassword.error.wrongPassword': 'Mevcut şifre yanlış',
  'userUpdatePassword.error.general': 'Bir hata meydana geldi',
  'userUpdatePassword.label.currentPassword': 'Mevcut Şifre',
  'userUpdatePassword.label.newPassword': 'Yeni Şifre',
  'userUpdatePassword.label.repeatNewPassword': 'Yeni Şifre Tekrar',
  'userUpdatePassword.button.update': 'Güncelle',
  'generalMenuList.title': 'GENEL',
  'generalMenuList.home': 'Ana Sayfa',
  'copyright.madeIn': "Türkiye'de",
  'copyright.developedWith': 'ile geliştirildi © ',
  'createProject.error.general': 'Bir hata meydana geldi',
  'createProject.title': 'Proje Oluştur',
  'createProject.label.name': 'Proje İsmi*',
  'createProject.label.url': 'URL*',
  'createProject.label.description': 'Açıklama',
  'createProject.button.create': 'Oluştur',
  'menuList.module': 'MODÜL',
  'menuList.charts': 'Grafikler',
  'menuList.tables': 'Tablolar',
  'menuList.project': 'PROJE',
  'menuList.users': 'Kullanıcılar',
  'menuList.actions': 'Aksiyonlar',
  'menuList.logs': 'Hareketler',
  'menuList.notifications': 'Bildirimler',
  'menuList.settings': 'Ayarlar',
  'menuList.permission': 'İZİN',
  'menuList.userPermissions': 'Kullanıcı İzinleri',
  'menuList.actionPermissions': 'Aksiyon İzinleri',
  'menuList.account': 'HESAP',
  'menuList.userProfile': 'Kullanıcı Künyesi',
  'menuList.accountSettings': 'Hesap Ayarları',
  'noProjects.text': 'Henüz proje oluşturulmamış',
  'noProjects.button.create': 'Hemen Oluştur',
  'search.home': 'Ana Sayfa',
  'search.updateSettings': 'Ayarları Güncelle',
  'search.updateSettings.description': 'Bu sayfada proje ayarlarını güncelleyebilirsiniz',
  'search.users': 'Kullanıcılar',
  'search.users.description': 'Bu sayfada proje kullanıcılarını görüntüleyebilirsiniz',
  'search.addUser': 'Kullanıcı Ekle',
  'search.addUser.description': 'Bu sayfada proje kullanıcısı ekleyebilirsiniz',
  'search.updateUser': 'Kullanıcı Güncelle',
  'search.updateUser.description': 'Bu sayfada proje kullanıcısı güncelleyebilirsiniz',
  'search.deleteUser': 'Kullanıcı Sil',
  'search.deleteUser.description': 'Bu sayfada proje kullanıcısı silebilirsiniz',
  'search.logs': 'Hareketler',
  'search.logs.description': 'Bu sayfada proje hareketlerini/log"larını görüntüleyebilirsiniz',
  'search.projectPermissions': 'Proje Yetkileri',
  'search.projectPermissions.description': 'Bu sayfada proje yetkilerini görüntüleyebilirsiniz',
  'search.updateProjectPermission': 'Proje Yetkisini Güncelle',
  'search.updateProjectPermission.description': 'Bu sayfada proje yetkilerini güncelleyebilirsiniz',
  'search.actionPermissions': 'Aksiyon Yetkileri',
  'search.actionPermissions.description': 'Bu sayfada aksiyon yetkilerini görüntüleyebilirsiniz',
  'search.deleteActionPermission': 'Aksiyon Yetkisi Sil',
  'search.deleteActionPermission.description': 'Bu sayfada aksiyon yetkisi silebilirsiniz',
  'search.addActionPermission': 'Aksiyon Yetkisi Ekle',
  'search.addActionPermission.description': 'Bu sayfada aksiyon yetkisi ekleyebilirsiniz',
  'search.updateActionPermission': 'Aksiyon Yetkisi Güncelle',
  'search.updateActionPermission.description': 'Bu sayfada aksiyon yetkisi güncelleyebilirsiniz',
  'search.charts': 'Grafikler',
  'search.charts.description': 'Bu sayfada garfikleri görüntüleyebilirsiniz',
  'search.createAction': 'Aksiyon Oluştur',
  'search.createAction.description': 'Bu sayfada aksiyon oluşturabilirsiniz',
  'search.tables': 'Tablolar',
  'search.tables.description': 'Bu sayfada tabloları görüntüleyebilirsiniz',
  'search.addTable': 'Tablo Ekle',
  'search.addTable.description': 'Bu sayfada tablo ekleyebilirsiniz',
  'search.deleteTable': 'Tablo Sil',
  'search.deleteTable.description': 'Bu sayfada tablo silebilirsiniz',
  'search.updateTable': 'Tablo Güncelle',
  'search.updateTable.description': 'Bu sayfada tablo güncelleyebilirsiniz',
  'search.minCharacter': 'En az 1 karaketer girilmelidir',
  'search.noResults': 'Hiç sonuç bulamadık',
  'search.placeholder': 'Ara...',
  'userSettings.success.save': 'Başarıyla kaydedildi',
  'userSettings.error.general': 'Bir hata oluştu',
  'userSettings.label.photo': 'Fotoğraf',
  'userSettings.button.change': 'Değiştir',
  'userSettings.label.email': 'E-mail',
  'userSettings.label.name': 'İsim',
  'userSettings.label.language': 'Dil',
  'userSettings.label.phone': 'Telefon',
  'userSettings.text.registeredWith': 'ile kayıt olunmuş',
  'userSettings.button.save': 'Kaydet',
  'userAdvancedSettings.error.general': 'Bir hata oluştu',
  'userAdvancedSettings.deleteAccount.title': 'Hesabının silinmesini onaylıyor musun?',
  'userAdvancedSettings.deleteAccount.description': 'Onaylıyor musun?',
  'userAdvancedSettings.button.advancedSettings': 'Gelişmiş Ayarlar',
  'userAdvancedSettings.button.deleteAccount': 'Hesabı Sil',
  'header.button.createAction': 'Aksiyon Oluştur',
  'header.button.accountSettings': 'Hesap Ayarları',
  'header.button.changePassword': 'Şifre Değiştir',
  'header.button.userProfile': 'Kullanıcı Künyesi',
  'header.button.documentation': 'Döküman',
  'header.button.logout': 'Çıkış Yap',
  'userSettingsPopup.title': 'Hesap Ayarları',
  'userUpdatePasswordPopup.title': 'Şifre Değiştir',
  'mobileRoot.leftMenu.logout': 'Çıkış Yap',
  'mobileRoot.allApis.title': 'Aksiyonlar',
  'mobileRoot.userSettings.updateProfile': 'Profili Güncelle',
  'mobileRoot.userSettings.changePassword': 'Şifre Değiştir',
  'serviceError.message': 'Servislerimiz ile ilgili şu an bir sıkıntı yaşıyoruz. Bu konu üzerinde çalışıyoruz.',
  'withRootLogic.error.sessionExpired': 'Oturum süreniz doldu. Lütfen tekrar giriş yapınız.',
  'withRootLogic.error.general': 'Bir hata meydana geldi',
  'header.button.createApi': 'Aksiyon Oluştur',
  'buildApi.top.preview': 'Kaydet & Önizle',
  'actionGroupPermissions.edit': 'düzenle',
  'actionGroupPermissions.delete': 'sil',
  'actionGroupPermissions.searchPlaceholder': 'İsme göre ara...',
  'actionGroupPermissions.addButton': 'Aksiyon Yetki Grubu Ekle',
  'actionGroupPermissions.noGroups': 'Hiç eklenmiş aksiyon yetki grubu yok',
  'actionGroupPermissions.noFilteredGroups': 'Bu filtreye göre hiç aksiyon yetki grubu yok',
  'actionGroupPermissions.error.general': 'Bir hata oluştu',
  'actionGroupPermissions.form.alreadyAdmin': 'Zaten yönetici',
  'actionGroupPermissions.form.saveButton': 'Kaydet',
  'actionGroupPermissions.form.addButton': 'Ekle',
  'actionGroupPermissions.form.editTitle': 'Aksiyon Yetki Grubunu Düzenle',
  'actionGroupPermissions.form.addTitle': 'Aksiyon Yetki Grubu Ekle',
  'actionGroupPermissions.detail.groupName': 'Grup İsmi',
  'actionGroupPermissions.detail.actions': 'Aksiyonlar',
  'actionGroupPermissions.detail.users': 'Kullanıcılar',
  'actionGroupPermissions.dropdown.noItems': 'Hiç bir şey yok',
  'actionGroupPermissions.dropdown.noFilteredItems': 'Bu filtreye göre hiç bir şey yok',
  'actionGroupPermissions.form.error.emptyName': 'Grup ismi boş olamaz',
  'actionGroupPermissions.form.error.noActions': 'En az bir aksiyon eklenmelidir',
  'actionGroupPermissions.form.error.noUsers': 'En az bir kullanıcı eklenmelidir',
  'actionGroupPermissions.form.success': 'İşlem başarıyla gerçekleşti',
  'actionGroupPermissions.form.label.groupName': 'Grup İsmi*',
  'actionGroupPermissions.form.placeholder.groupName': 'Grup ismi giriniz...',
  'actionGroupPermissions.form.label.actions': 'Aksiyonlar',
  'actionGroupPermissions.form.placeholder.addAction': '+ Aksiyon Ekle',
  'actionGroupPermissions.form.noActionsToAdd': 'Hiç eklenecek aksiyon yok',
  'actionGroupPermissions.form.noFilteredActions': 'Bu filtreye göre hiç aksiyon yok',
  'actionGroupPermissions.form.noAddedActions': 'Hiç eklenmiş aksiyon yok',
  'actionGroupPermissions.form.label.users': 'Kullanıcılar',
  'actionGroupPermissions.form.placeholder.addUser': '+ Kullanıcı Ekle',
  'actionGroupPermissions.form.noUsersToAdd': 'Hiç eklenecek kullanıcı yok',
  'actionGroupPermissions.form.noFilteredUsers': 'Bu filtreye göre hiç kullanıcı yok',
  'actionGroupPermissions.form.noAddedUsers': 'Hiç eklenmiş kullanıcı yok',
  'actionGroupPermissions.delete.title': 'Silmek istediğine emin misin?',
  'actions.update.success': 'Aksiyonlar başarıyla güncellendi',
  'actions.order.success': 'Aksiyon sıraları başarıyla güncellendi',
  'actions.groups.order.success': 'Aksiyon grup sıraları başarıyla güncellendi',
  'actions.no.actions': 'Hiç aksiyon yok',
  'actions.change.action.order': 'Aksiyon Sırasını Değiştir',
  'actions.change.group.order': 'Grup Sırasını Değiştir',
  'actions.edit.titles': 'Başlıkları Düzenle',
  'actions.mobile.information': 'Ekranın alt kısmında yer alan merkezi ikona tıklayarak tüm aksiyonlara hızlıca ulaşabilirsiniz.',
  'actions.title': 'Aksiyonlar',
  'actions.move.select.group': 'Grup Seç',
  'actions.move.select.action': 'Aksiyon Seç',
  'moveActions.back': 'Geri',
  'actions.cancel': 'İptal',
  'actions.save': 'Kaydet',
  'apiExecute.steps.firstForm': 'İlk Form',
  'apiExecute.steps.actionForm': 'Aksiyon Form',
  'apiExecute.error.general': 'Bir hata oluştu',
  'apiExecute.error.unknownSideContent': 'Bilinmeyen yan içerik tipi',
  'apiExecute.ruleErrors.title': 'Hatalar',
  'apiExecute.ruleErrors.count': '{count} hata bulundu',
  'apiExecute.moreInfo.title': 'Detay',
  'apiExecute.moreInfo.label.title': 'Başlık',
  'apiExecute.moreInfo.label.group': 'Grup',
  'apiExecute.moreInfo.label.description': 'Açıklama',
  'apiExecute.moreInfo.label.url': 'URL',
  'apiExecute.moreInfo.label.type': 'Tip',
  'apiExecute.moreInfo.label.actionHeader': 'Header',
  'apiExecute.moreInfo.label.actionBody': 'Body',
  'apiExecute.moreInfo.label.defaultActionBody': 'Default Body',
  'apiExecute.moreInfo.label.actionUrlBody': 'URL Query Parameter',
  'apiExecute.moreInfo.label.defaultActionUrlBody': 'Default URL Query Parameter',
  'apiExecute.moreInfo.label.generalFormElements': 'Genel Form Elemanları',
  'apiExecute.moreInfo.label.settings': 'Ayarlar',
  'apiExecute.moreInfo.label.actionOtherBodies': 'Diğer',
  'apiExecute.header.errorCount': '{count} adet hata',
  'apiExecute.logs.error.fetch': 'Aksiyon hareketleri çekilirken bir hata oluştu',
  'apiExecute.logs.detail.status.success': 'başarılı',
  'apiExecute.logs.detail.status.error': 'hata',
  'apiExecute.logs.detail.tab.data': 'data',
  'apiExecute.logs.detail.tab.header': 'header',
  'apiExecute.logs.detail.tab.other': 'diğer',
  'apiExecute.logs.detail.tab.settings': 'ayarlar',
  'apiExecute.logs.detail.tab.status': 'status',
  'apiExecute.logs.detail.title': 'Detay',
  'apiExecute.logs.detail.requestData': 'İstek Verileri:',
  'apiExecute.logs.detail.result': 'Sonuç:',
  'apiExecute.logs.noHistory': 'Hiç geçmiş yok',
  'apiExecute.logs.loadMore': 'Daha Fazla',
  'apiExecute.initialValues.error': 'Bir hata oluştu',
  'apiExecute.initialValues.continue': 'Devam Et',
  'apiExecute.submitButton.submit': 'Tamamla',
  'apiExecute.submitButton.success': 'İşlem başarıyla gerçekleşti',
  'apiExecute.submitButton.error': 'Bir hata oluştu',
  'charts.error.fetch': 'Bir hata meydana geldi',
  'charts.noCharts': 'Hiç eklenmiş grafik yok',
  'charts.title': 'Grafikler',
  'charts.createChart': 'Grafik Oluştur',
  'charts.form.edit.title': 'Grafik Düzenle',
  'charts.form.create.title': 'Grafik Oluştur',
  'charts.form.delete.success': 'Grafik başarıyla silindi',
  'charts.form.error.general': 'Bir hata meydana geldi',
  'charts.form.update.success': 'Grafik başarıyla güncellendi',
  'charts.form.create.success': 'Grafik başarıyla eklendi',
  'charts.form.label.title': 'Başlık',
  'charts.form.placeholder.title': 'Başlık*',
  'charts.form.label.method': 'Method',
  'charts.form.placeholder.method': 'Bir method seç*',
  'charts.form.label.url': 'Url',
  'charts.form.label.dataKey': 'Data Key',
  'charts.form.tooltip.dataKey':
    'Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")',
  'charts.form.placeholder.dataKey': 'Data Key',
  'charts.form.label.apiHeader': 'API Header',
  'charts.form.label.chartType': 'Grafik Tipi',
  'charts.form.button.back': 'Geri',
  'charts.form.delete.title': 'Silmek istediğine emin misin?',
  'charts.form.delete.description':
    'Bu grafik widget olarak kullanılıyorsa dahi silineceketir. İşlem tamamlandıktan sonra anasayfayı kontrol ediniz.',
  'charts.form.button.delete': 'Sil',
  'charts.form.button.save': 'Kaydet',
  'charts.form.chartType.sampleOutput': 'Örnek Çıktı',
  'charts.form.chartType.sampleOutputDescription':
    "Bu grafik tipini kullanmak için, belirlediğin url'den aşağıdakine benzer bir response dönmesi gerekir.",
  'chooseCreateApiType.manual.title': 'Manuel Oluştur',
  'chooseCreateApiType.manual.description': 'Manuel olarak oluşturun',
  'chooseCreateApiType.import.title': 'İçe Aktar',
  'chooseCreateApiType.import.description': 'Koleksiyonu içe aktarın',
  'chooseCreateApiType.postman.title': 'Postman Builder',
  'chooseCreateApiType.postman.description': 'Hazır olan bir Postman koleksiyonu ile entegre edin',
  'chooseCreateApiType.button.home': 'Ana Sayfa',
  'chooseCreateApiType.title': 'Yeni bir aksiyon oluştur',
  'home.welcome': 'Hoş Geldin',
  'home.error.general': 'Bir hata meydana geldi',
  'home.widget.deleted': 'Bu widget silinmiş',
  'home.widget.delete': 'sil',
  'home.widget.add': 'Ekle',
  'home.widget.noWidgets': 'Henüz hiç widget eklenmedi',
  'home.button.cancel': 'İptal Et',
  'home.button.save': 'Kaydet',
  'home.button.edit': 'Düzenle',
  'home.widgetGuide.add.title': 'Ekle',
  'home.widgetGuide.add.chart': 'Grafik',
  'home.widgetGuide.add.or': 'veya',
  'home.widgetGuide.add.table': 'Tablo',
  'home.widgetGuide.add.description': 'ekleyebilirsin.',
  'home.widgetGuide.edit.title': 'Düzenle',
  'home.widgetGuide.edit.description': 'Eklediğin grafik ya da tabloyu ana sayfada sürükle/bırak ile istediğin gibi konumlandırabilirsin.',
  'home.widgetGuide.ready.title': 'Hazır',
  'home.widgetGuide.ready.description': 'Ana sayfa düzenlediğin şekilde kalır.',
  'home.dragAndDrop.unknownWidget': 'Bilinmeyen Widget',
  'home.dragAndDrop.noWidgets': 'Eklenebilecek widget yok',
  'loginWithAccount.error.message': 'Bir hata meydana geldi...',
  'loginWithAccount.button.loginPage': 'Giriş Sayfası',
  'loginWithAccount.loading.message': 'Giriş yapılıyor',
  'notFoundError.title': '404 | Kaybolmuş gibi görünüyorsunuz.',
  'notFoundError.button.home': 'Ana Sayfaya Dön',
  'projectLogs.filters.date.placeholder': 'Tarih Seç',
  'projectLogs.filters.orderBy.fromTodayToPast': 'Bugünden geçmişe',
  'projectLogs.filters.orderBy.fromPastToToday': 'Geçmişten bugüne',
  'projectLogs.filters.type.placeholder': 'Tip seç',
  'projectLogs.filters.user.placeholder': 'Kullanıcı Seç',
  'projectLogs.filters.button.clear': 'Temizle',
  'projectLogs.filters.button.apply': 'Uygula',
  'projectLogs.filters.title': 'Filtreler',
  'projectLogs.tag.createdProjectUser': 'Kullanıcı Ekleme',
  'projectLogs.tag.deletedProjectUser': 'Kullanıcı Silme',
  'projectLogs.tag.leaveUserFromProject': 'Projeden Ayrılma',
  'projectLogs.tag.createdProjectApiPermission': 'Aksiyon İzin Ekleme',
  'projectLogs.tag.createdApi': 'Aksiyon Ekleme',
  'projectLogs.tag.createdApiCollection': 'Aksiyon Dışa Aktarma',
  'projectLogs.tag.executedApiCollection': 'Aksiyon İçe Aktarma',
  'projectLogs.tag.deletedApi': 'Aksiyon Silme',
  'projectLogs.tag.createdChart': 'Grafik Ekleme',
  'projectLogs.tag.createdTable': 'Tablo Ekleme',
  'projectLogs.tag.deletedChart': 'Grafik Silme',
  'projectLogs.tag.deletedProjectApiPermission': 'Aksiyon İzin Silme',
  'projectLogs.tag.deletedTable': 'Tablo Silme',
  'projectLogs.tag.updatedProjectApiPermission': 'Aksiyon İzin Güncelleme',
  'projectLogs.tag.updatedProject': 'Proje Güncelleme',
  'projectLogs.tag.updatedChart': 'Grafik Güncelleme',
  'projectLogs.tag.updatedTable': 'Tablo Güncelleme',
  'projectLogs.tag.executedApi': 'Aksiyon İşleme',
  'projectLogs.tag.userLogged': 'Kullanıcı Girişi',
  'projectLogs.tag.general': 'Genel',
  'projectLogs.error.getProjectLogs': 'Bir hata meydana geldi',
  'projectLogs.noLogs': 'Hiç hareket yok',
  'projectLogs.moreButton': 'Daha fazla',
  'projectLogs.title': 'Proje Hareketleri',
  'projectSettings.menu.settings': 'Ayarlar',
  'projectSettings.menu.advancedSettings': 'Gelişmiş Ayarlar',
  'projectSettings.menu.others': 'Diğer',
  'projectSettings.title': 'Proje Ayarları',
  'projectSettings.general.toast.success': 'Bilgiler güncellendi',
  'projectSettings.general.toast.error': 'Bir hata oluştu',
  'projectSettings.general.button.change': 'Değiştir',
  'projectSettings.general.label.name': 'Proje Adı',
  'projectSettings.general.error.required': 'Boş olamaz',
  'projectSettings.general.placeholder.name': 'Benim Projem',
  'projectSettings.general.label.description': 'Açıklama',
  'projectSettings.general.placeholder.description': 'Bir şey yaz',
  'projectSettings.general.button.save': 'Kaydet',
  'projectSettings.advanced.toast.success': 'Bilgiler güncellendi',
  'projectSettings.advanced.toast.error': 'Bir hata oluştu',
  'projectSettings.advanced.label.baseColor': 'Temel Renk',
  'projectSettings.advanced.info.noColor': 'Hiç renk girilmemiş',
  'projectSettings.advanced.label.url': 'URL',
  'projectSettings.advanced.error.required': 'Boş olamaz',
  'projectSettings.advanced.error.invalidJson': 'Geçersiz JSON',
  'projectSettings.advanced.label.apiDefaultHeaders': 'API Default Headers',
  'projectSettings.advanced.label.apiDefaultBody': 'API Default Body',
  'projectSettings.advanced.label.apiDefaultUrlQueryParams': 'API Default URL Query Params',
  'projectSettings.advanced.label.configs': 'Configs',
  'projectSettings.advanced.button.save': 'Kaydet',
  'projectSettings.other.import.title': 'İçe Aktar',
  'projectSettings.other.import.description': 'Aksiyon koleksiyonunu içeri aktarabilirsiniz.',
  'projectSettings.other.import.button': 'Aksiyonları İçe Aktar',
  'projectSettings.other.export.title': 'Dışa Aktar',
  'projectSettings.other.export.description': 'Mevcut tüm aksiyonları JSON dosyası olarak dışarı aktarabilirsiniz.',
  'projectSettings.other.export.button': 'Aksiyonları Dışa Aktar',
  'projectSettings.other.delete.title': 'Projeyi Sil',
  'projectSettings.other.delete.description': 'Projeyi tamamen silebilirsiniz. Proje silindikten sonra tekrar kurtarılamaz',
  'projectSettings.other.delete.button': 'Projeyi Sil',
  'projectSettings.delete.popup.title': 'Projeyi Sil',
  'projectSettings.delete.popup.description': 'Projeyi silmek üzeresin. Silme işlemine devam etmek için "{text}" yazmalısın.',
  'projectSettings.delete.popup.button.continue': 'Devam Et',
  'projectSettings.delete.popup.warning':
    'Proje silindikten sonra geri alınamaz. Şu an projeyi silmek için son aşamadasın. Silmek istediğine emin misin?',
  'projectSettings.delete.popup.button.cancel': 'Vazgeç',
  'projectSettings.delete.popup.button.confirm': 'Eminim, Sil',
  'projectSettings.delete.toast.success': 'Proje başarıyla silindi',
  'projectSettings.delete.toast.redirect': 'Yönlendiriliyorsunuz...',
  'projectSettings.delete.toast.error': 'Bir hata meydana geldi',
  'projectSettings.export.popup.title': 'Aksiyonları Dışa Aktar',
  'projectSettings.export.popup.button.prepare': 'Hazırla!',
  'projectSettings.export.toast.error': 'Bir hata meydana geldi',
  'projectSettings.export.home.description':
    'Aksiyonları dışa aktarma sihirbazına hoşgeldin! Seçtiğin aksiyonları kolayca dışa aktarabilirsin.',
  'projectSettings.export.download.loading': 'Dosyan hazırlanıyor...',
  'projectSettings.export.download.description':
    'Dosyan hazırlandı! Otomatik olarak indirilme başlamadıysa aşağıdaki butonu kullanabilirsin.',
  'projectSettings.export.download.button': 'İndir',
  'projectSettings.export.variables.description': 'Dışa aktarılacak aksiyonlar için varsayılan değişkenleri düzenle.',
  'projectSettings.export.variables.header.title': 'Default Header',
  'projectSettings.export.variables.body.title': 'Default Body',
  'projectSettings.export.variables.queryParams.title': 'Default URL Query Params',
  'projectSettings.export.variables.configs.title': 'Configs',
  'projectSettings.import.popup.title': 'Aksiyonları İçe Aktar',
  'projectSettings.import.popup.button.import': 'İçe Aktar',
  'projectSettings.import.toast.error': 'Bir hata meydana geldi',
  'projectSettings.import.upload.label': 'Bir koleksiyon yükle ya da sürükle bırak',
  'projectSettings.import.upload.error.invalidJson': 'Geçersiz JSON yapısı',
  'projectSettings.import.variables.header.title': 'Default Header',
  'projectSettings.import.variables.body.title': 'Default Body',
  'projectSettings.import.variables.queryParams.title': 'Default URL Query Params',
  'projectSettings.import.variables.configs.title': 'Configs',
  'projectSettings.import.success.message': 'İşlem başarıyla gerçekleşti. Anasayfaya yönlendiriliyorsunuz...',
  'projectSettings.delete.popup.confirmationText': 'projemi silmek istiyorum',
  'popup.button.back': 'Geri',
  'popup.button.next': 'İleri',
  'projectUserImprint.title': 'Kullanıcı Künyesi',
  'projectUserImprint.item.title.joinedDate': 'Projeye Katılma Tarihi',
  'projectUserImprint.toast.error': 'Bir hata meydana geldi',
  'projectUserLeave.popup.title': 'Projeden ayrılmak istediğine emin misin?',
  'projectUserLeave.popup.description': 'Projeden ayrıldıktan sonra işlem geri alınamaz.',
  'projectUserLeave.button.leaveProject': 'Projeden Ayrıl',
  'projectUserLeave.toast.error': 'Bir hata meydana geldi',
  'projectUserNotifications.title': 'Proje Bildirimleri',
  'projectUserNotifications.noNotifications': 'Hiç bildirim yok',
  'projectUserPermissions.title': 'Proje Yetkileri',
  'projectUserPermissions.noProjectUsers': 'Hiç projeye eklenmiş kullanıcı yok',
  'projectUserPermissions.filter.placeholder': "Email'e göre kullanıcı ara...",
  'projectUserPermissions.toast.error': 'Bir hata meydana geldi',
  'projectUserPermissions.backButton': 'Geri',
  'tables.createTable': 'Tablo Oluştur',
  'tables.noTable': 'Hiç eklenmiş tablo yok',
  'tables.edit': 'düzenle',
  'tables.save': 'Kaydet',
  'tables.delete': 'Sil',
  'tables.deletePopup.confirmationText': 'Silmek istediğine emin misin?',
  'tables.deletePopup.description':
    'Bu tablo widget olarak kullanılıyorsa dahi silineceketir. İşlem tamamlandıktan sonra anasayfayı kontrol ediniz.',
  'tables.label': 'Başlık',
  'tables.labelPlaceholder': 'Başlık',
  'tables.method': 'Bir method seç*',
  'tables.methodPlaceholder': 'Bir method seç*',
  'tables.url': 'Url*',
  'tables.dataKey': 'Data Key',
  'tables.paginationStatus': 'API Dinamik Sayfalama Durumu',
  'tables.totalKey': 'Total Key*',
  'tables.limitKey': 'Limit Key*',
  'tables.columns': 'Tablo Kolonları*',
  'tables.otherSettings': 'Diğer',
  'tables.back': 'Geri',
  'tables.dataKeyPlaceholder': 'Data Key',
  'tables.emptyDataWrapper': 'Boş gelen veri yerine "-" karakteri yazılsın',
  'tables.limitKeyTooltip': 'Pagination için limit key',
  'tables.paginationStatusTooltip': 'API Dinamik Sayfalama Durumu',
  'tables.dataKeyTooltip':
    'Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")',
  'tables.totalKeyTooltip': 'Pagination için total count key',
  'tables.totalKeyPlaceholder': 'Total Key*',
  'users.deletePopup.confirmationText': 'Kullanıcı silinemez',
  'users.toast.error': 'Bir hata oluştu',
  'users.title': 'Kullanıcılar',
  'users.filter.placeholder': "Email'e göre kullanıcı ara...",
  'users.addUserButton': 'Kullanıcı Ekle',
  'users.noProjectUsers': 'Hiç eklenmiş kullanıcı yok',
  'users.addUserPopup.title': 'Kullanıcı Ekle',
  'users.addUserPopup.emailPlaceholder': 'Kullanıcı E-mail',
  'users.addUserPopup.addButton': 'Ekle',
  'users.deletePopup.successMessage': 'Projeden ayrıldın',
  'users.deletePopup.removeUserSuccessMessage': 'Kullanıcı silindi',
  'users.deletePopup.leaveProjectConfirmationText': 'Projeden ayrılmak istediğine emin misin?',
  'users.deletePopup.removeUserConfirmationText': 'Silmek istediğine emin misin?',
  'users.deletePopup.leaveProjectDescriptionText': 'Kendini projeden çıkarmak üzeresin.',
  'users.deletePopup.yesLeaveProjectButton': 'Evet, ayrıl',
  'users.toast.validationError': 'Geçersiz e-mail adresi',
  'users.toast.success': 'İşlem başarıyla gerçekleşti',
  'dynamicFieldErrors.required': 'Bu alan zorunludur',
  'dynamicFieldErrors.max': '{number} sayısından büyük olamaz',
  'dynamicFieldErrors.min': '{number} sayısından küçük olamaz',
  'dynamicFieldErrors.unknownError': 'Bilinmeyen bir hata',
  unknownFile: 'Bilinmeyen Dosya İsmi',
  'userType.admin': 'Yönetici',
  'userType.member': 'Üye',
  'buildApi.topUndoHistory.undo': 'geri',
  'buildApi.topUndoHistory.redo': 'ileri',
  'buildApi.rightPanel.fieldSettings.description.type.default': 'Varsayılan',
  'buildApi.rightPanel.fieldSettings.description.type.formTopHeader': 'Form Üst Başlık',
  'table.filters.manage.content.addNewFilter': 'Yeni Filtre Ekle',
  'table.filters.manage.content.noFilters': 'Henüz filtre eklenmemiş',
  'table.filters.manage.content.list.actions': 'İşlemler',
  'table.filters.manage.content.list.title': 'Başlık',
  'table.filters.manage.content.edit.back': 'Geri',
  'table.filters.manage.content.edit.title': 'Başlık',
  'table.filters.manage.content.edit.key': 'Key',
  'table.filters.manage.content.edit.remoteSource': 'Remote Source',
  'table.filters.manage.content.edit.save': 'Kaydet',
  'table.filters.manage.content.title': 'Tüm Filtreler',
  'table.popup.form.filters': 'Filtreler',
  'table.popup.form.see.all.filters': 'Filtreleri Görüntüle',
  'buildApi.fieldContextMenu.clone': 'Klonla',
  'buildApi.fieldContextMenu.changeElement': 'Elemanı Değiştir',
  'buildApi.fieldContextMenu.delete': 'Sil',
  'buildApi.fieldContextMenu.changeElementType': 'Tip Değiştir',
  'buildApi.fieldContextMenu.selectType': 'Tip Seç',
  'buildApi.fieldContextMenu.changeElementTypeButton': 'Değiştir',
  'remoteInputDropdown.error.unknownError': 'Bir hata meydana geldi.',
  'buildApi.dateAdvancedSetting.hourSettingTitle': 'Saat Durumu',
  'buildApi.dateAdvancedSetting.outcomeFormatSettingTitle': 'Gelen Format',
  'buildApi.dateAdvancedSetting.outputFormatSettingTitle': 'Gidecek Format',
  'buildApi.dateAdvancedSetting.utcModeActive': 'UTC modu aktif et',
  'buildApi.dateAdvancedSetting.utcModeActiveInfo': 'UTC modu aktif.',
  'buildApi.dateAdvancedSetting.change': 'Değiştir',
  'buildApi.dateAdvancedSetting.example': 'Örn:',
  'buildApi.field.errorText': 'Bir hata meydana geldi.',
  'buildApi.image.advanced.outputType.title': 'Giden Tip',
  'buildApi.image.advanced.outputType.text': 'Seçilen fotoğraflar {informationText} olarak gönderilecektir.',
  'buildApi.image.advanced.outputType.information': 'Fotoğraf tipleri API body tipine göre otomatik belirlenmektedir.',
  'buildApi.rightPanel.fieldSettings.exclude.moreSettings': 'Daha Fazla',
  'buildApi.rightPanel.fieldSettings.exclude.default': 'Her şartta dahil etme',
  'buildApi.rightPanel.fieldSettings.exclude.isDirty': 'Eleman değeri değişmediği zaman dahil etme',
  'buildApi.image.error.imageUploadError': 'Fotoğraf yüklenirken bir hata meydana geldi.',
  'buildApi.image.remove': 'Kaldır',
  'buildApi.image.upload': 'Fotoğraf Seç',
}
