import i18next from 'i18next'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getAllProjects, getMe, getMeProjectPermissions, getProjectApis, getProjectDetail } from '@/api'
import { INAVO_COLOR } from '@/constants'
import AppLoading from '@/containers/Root/AppLoading/AppLoading'
import ServiceError from '@/containers/Root/ServiceError/ServiceError'
import useActiveProjectIdActions from '@/hooks/useActiveProjectIdActions'
import { appReducerActions, appReducerSelectors } from '@/store/reducers/app'
import { authReducerActions } from '@/store/reducers/auth'
import { getCurrentActiveProjectId, setApexColor } from '@/utils'
import { removeUserTokenStorage } from '@/utils/localStorageActions'

// eslint-disable-next-line react/display-name
const withRootLogic = WrapperComponent => props => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { handleSetActiveProjectId } = useActiveProjectIdActions()

  const [hasServiceError, setHasServiceError] = useState(false)

  const isMounted = useRef(null)

  const { isLoading: isLoadingApp } = useSelector(state => {
    const { isLoading } = state.app

    return {
      isLoading,
    }
  })
  const user = useSelector(state => state.auth.user)
  const selectedProject = useSelector(appReducerSelectors.selectedProjectSelector)

  const activeProjectId = selectedProject?.id
  const isSelectedProjectOwner = selectedProject?.isOwner === true
  const hasUser = !!user

  const handleFirstRequests = async () => {
    try {
      dispatch(appReducerActions.setIsLoading(true))

      const [userInfo, allProjects] = await Promise.all([getMe(), getAllProjects()]).then(([userInfo, allProjects]) => [
        userInfo.data.user,
        allProjects.data,
      ])
      dispatch(authReducerActions.setUser(userInfo))
      dispatch(appReducerActions.setProjects(allProjects))

      if (allProjects.length === 0) return

      let _activeProjectId = Number(getCurrentActiveProjectId())
      const hasProject = !!allProjects.find(project => project.id === _activeProjectId)
      if (!_activeProjectId || (_activeProjectId && !hasProject)) {
        _activeProjectId = allProjects[0].id

        handleSetActiveProjectId(_activeProjectId)
      }

      const isProjectOwner = allProjects.find(project => project.id === _activeProjectId)?.isOwner
      await handleRequestRelatedToProject({ isProjectOwner })
    } catch (err) {
      const errorStatusCode = err?.response?.status

      if (errorStatusCode === 401) {
        toast.error(i18next.t('withRootLogic.error.sessionExpired'))

        dispatch(authReducerActions.removeUser())
        removeUserTokenStorage()
        navigation('/login')

        return
      }

      setHasServiceError(true)
    } finally {
      dispatch(appReducerActions.setIsLoading(false))
      isMounted.current = true
    }
  }

  const handleActiveProjectApis = async () => {
    const projectApisRes = await getProjectApis()
    dispatch(appReducerActions.setProjectApis(projectApisRes.data))
  }

  const handleGetProjectPermissions = async () => {
    const projectPermissionsRes = await getMeProjectPermissions()
    dispatch(appReducerActions.setProjectUserPermissions(projectPermissionsRes.data.permissions))
  }

  const handleAdjustByProjectDetail = projectDetail => {
    const { settings } = projectDetail

    setApexColor(settings?.baseColor || INAVO_COLOR)
  }

  const handleGetProjectSettings = async () => {
    const projectDetailRes = await getProjectDetail()
    const projectDetail = projectDetailRes.data.project

    dispatch(appReducerActions.setProjectSettings(projectDetail))
    handleAdjustByProjectDetail(projectDetail)
  }

  const handleRequestRelatedToProject = async ({ isProjectOwner = isSelectedProjectOwner } = {}) => {
    await Promise.all([handleActiveProjectApis(), handleGetProjectSettings(), ...(!isProjectOwner ? [handleGetProjectPermissions()] : [])])
  }

  const handleRequestsAfterChangingActiveProject = async () => {
    try {
      dispatch(appReducerActions.setIsLoading(true))

      await handleRequestRelatedToProject()
    } catch (err) {
      toast.error(i18next.t('withRootLogic.error.general'))
    } finally {
      dispatch(appReducerActions.setIsLoading(false))
    }
  }

  const handleSetDocumentTitle = projectName => {
    document.title = projectName ? `${projectName} | inavo Console` : 'inavo Console'
  }

  useEffect(() => {
    handleFirstRequests()
  }, [])

  useEffect(() => {
    !!activeProjectId && isMounted.current && handleRequestsAfterChangingActiveProject()
  }, [activeProjectId])

  useEffect(() => {
    !!selectedProject?.name && handleSetDocumentTitle(selectedProject?.name)

    return () => {
      handleSetDocumentTitle()
    }
  }, [selectedProject?.name])

  const getContent = content => {
    switch (true) {
      case (isLoadingApp && !hasServiceError) || !hasUser:
        return <AppLoading />
      case !isLoadingApp && hasServiceError:
        return <ServiceError />
      default:
        return content
    }
  }

  return <WrapperComponent {...props} getContent={getContent} />
}

export default withRootLogic
