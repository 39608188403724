import _ from 'lodash'

import ToggleSwitchComponent from '@/components/ToggleSwitch/ToggleSwitch'

export const DEFAULT_VALUES = {
  ON: true,
  OFF: false,
}

const ToggleSwitch = ({ field, handleChangeFieldValue }) => {
  const { value, properties = {} } = field

  // TODO: Maybe we should normalize it.
  const SWITCH_VALUES = {
    ON: !_.isUndefined(properties?.onValue) ? properties.onValue : DEFAULT_VALUES.ON,
    OFF: !_.isUndefined(properties?.offValue) ? properties.offValue : DEFAULT_VALUES.OFF,
  }
  const isChecked = value === SWITCH_VALUES.ON

  const getPreparedValue = _isChecked => (_isChecked ? SWITCH_VALUES.ON : SWITCH_VALUES.OFF)
  const handleChangeValue = e => {
    handleChangeFieldValue(getPreparedValue(e.target.checked))
  }

  return <ToggleSwitchComponent checked={isChecked} handleChangeValue={handleChangeValue} />
}

export default ToggleSwitch
