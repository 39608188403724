import i18next from 'i18next'

import { BODY_TYPE_CONFIGS, DATE_UTC_FORMAT, DEFAULT_LANGUAGE, LANGUAGES } from '@/constants'
import { getActiveLanguage, getActiveProjectId } from '@/utils/localStorageActions'
import { getSessionActiveProjectId } from '@/utils/sessionStorageActions'

export const isValidEmail = str => {
  return str.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const convertToBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

export const isMobile = () => window.innerWidth < 900

export const normalizeText = text => text.toString().toLocaleLowerCase('tr-TR')

export const filterText = (filterText = '', text = '') => normalizeText(text).includes(normalizeText(filterText))

export const setUrlQueryParameters = (url, queryObject) => {
  const base = 'http://dummy-base'
  const urlObj = new URL(url, base)

  const existingParams = new URLSearchParams(urlObj.search)

  Object.entries(queryObject).forEach(([key, value]) => {
    existingParams.set(key, value)
  })

  const newSearchParams = existingParams.toString()
  const resultUrl = url.startsWith('http') ? `${urlObj.origin}${urlObj.pathname}` : urlObj.pathname

  const result = newSearchParams ? `${resultUrl}?${newSearchParams}` : resultUrl
  return decodeURIComponent(result)
}

export const convertFile = (value, fileName = i18next.t('unknownFile')) => new File([new Blob([value])], fileName)

export const getFileType = file => (file?.type || '').split('/')[0]

export const getUserType = isAdmin => (isAdmin ? i18next.t('userType.admin') : i18next.t('userType.member'))

export const getValueOfJsonFile = async file => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(JSON.parse(event.target.result))
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })
}

export const setApexColor = color => {
  try {
    // eslint-disable-next-line no-undef
    Apex.colors = [color] // Yeah, it is not a problem :))

    // eslint-disable-next-line no-empty
  } catch (error) {}
}

// We know, it is bad practice.
export const getUpdatedBodyTypeConfig = (configs, configKey) => {
  // eslint-disable-next-line no-unused-vars
  const { isFormData, isFormUrlEncoded, ...otherConfigs } = configs

  return { ...otherConfigs, ...(configKey === BODY_TYPE_CONFIGS.NONE ? {} : { [configKey]: true }) }
}

export const getParsedJsonFile = async file => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(JSON.parse(event.target.result))
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })
}

export const isEmptyQuillEditorText = text => text.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !text.includes('<img')

export const getSortedItems = (items, { orderIndexKey }) => items.sort((a, b) => a[orderIndexKey] - b[orderIndexKey])

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
}

export const isOnApp = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /wv|webview|inavoapp/i.test(userAgent)
}

export const getActiveProjectLanguage = () => {
  const isValidLanguage = getActiveLanguage() && Object.values(LANGUAGES).includes(getActiveLanguage())

  return (isValidLanguage && getActiveLanguage()) || navigator.language || DEFAULT_LANGUAGE
}

// We use session for active project id because we need fresh data. The project can be changed by different inavo Console tabs.
export const getCurrentActiveProjectId = () => getSessionActiveProjectId() || getActiveProjectId()

export const checkIsUtcFormat = valueFormat => valueFormat === DATE_UTC_FORMAT
